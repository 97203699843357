<template>
  <!-- 技术服务 -->
  <div class="service-management">
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">交易信息</span>
        </div>
        <div class="change">
          <div
            v-for="(item,index) in types"
            :key="index"
            :class="{'active': currentIndex == index}"
            class="change-item"
            style="text-align: center;"
            @click="changeType(item, index)">
            <span >{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b">
          <span >当前位置：</span>
          <span >技术服务</span> >
          <span>{{currentType.name}}</span>
        </div>
        <div class="info" v-if="currentType.type === 0">
          <evaluate />
        </div>
        <div class="info" v-else>
          <div v-if="currentType.type === 1">
            <div class="docs">
              <div class="docs-item" v-for="(item, index) in msgs" :key="index">
                <span>
                  <img src="imgs/icon-decoration.png" alt="">
                  <span>{{item.name}}</span>
                </span>
                <span style="color: #a5a5a5;">{{item.date}}</span>
              </div>
            </div>
          </div>

          <div class="persons" v-if="currentType.type === 2">
            <div class="person-item" v-for="(item, index) in persons" :key="index">
              <div class="left">
                <img src="imgs/exhange-step2.png" alt="">
                <div class="people">
                  <p>
                    <span>{{item.name}}</span>
                    <el-button type="primary">在线咨询</el-button>
                  </p>
                  <p>主营板块：<span style="color: #000;">{{item.special}}</span></p>
                  <p>历史代理{{item.history}}项，最近30天代理{{item.latest}}项</p>
                </div>
              </div>
              <div class="center">
                <p>综合评分：<span class="score">{{item.score}}</span></p>
                <p>评论{{item.common}}条</p>
              </div>
              <div class="right">
                <p style="color: #000;">{{item.phone}}</p>
                <p>联系方式</p>
              </div>
            </div>
          </div>

          <div class="online" v-if="currentType.type === 3"></div>
          <div class="pagenation">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import evaluate from './components/evaluate'
export default {
  data() {
    return {
      types: [
        {name: '土地价值评估', type: 0},
        {name: '土地测绘', type: 1},
        {name: '经纪人', type: 2},
        {name: '线上磋商', type: 3},
      ],
      currentIndex: 0,
      currentType: {},
      msgs: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      persons: []
    }
  },
  components: {
    evaluate
  },
  watch: {
    $route: {
      handler: function() {
        let index = Number(this.$route.query.index);
        this.changeType(this.types[index], index)
      },
      immediate: true
    }
  },
  methods: {
    changeType(item, index) {
      // 切换类型
      this.currentIndex = index;
      this.currentType = item;
      this.$router.push({name: 'service', query: {index: index}})
      if(index == 1) {
        this.msgs = [
          {name: 'xxx区xxx镇xx村集体经济组织成员身份确认公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体经济组织成员身份确认公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
          {name: 'xxx区xxx镇xx村集体清产核资公告', date: '2020-10-27'},
        ]
      } else if(index === 2) {
        this.persons = [
          {name: '苏文皓', special: '耕地托管 四荒地待流转', history: 157, latest: 23, score: '5.0', common: 432, phone: 18765432581},
          {name: '苏文皓', special: '耕地托管 四荒地待流转', history: 157, latest: 23, score: '5.0', common: 432, phone: 18765432581},
          {name: '苏文皓', special: '耕地托管 四荒地待流转', history: 157, latest: 23, score: '4', common: 432, phone: 18765432581},
        ]
      }
    },
  }
}
</script>

<style lang="less" scoped>
.service-management {
  // background: #fff;
  padding-top: 20px;
  padding-bottom: 70px;
  .info {
    background: #fff;
    padding: 12px;
    .pagenation {
      text-align: center;
    }
  }
  .docs {
    // height: 640px;
    margin-bottom: 8px;
    // overflow-y: auto;
    // margin-top: 25px;
    .docs-item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 56px;
      color: #333333;
      padding-right: 8px;
      margin-bottom: 14px;
      border-bottom: 1px solid #c9c9c9;
      img {
        margin-right: 9px;
      }
    }
  }

  .persons {
    margin-bottom: 8px;
    .person-item {
      height: 90px;
      padding: 18px 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px dashed #00a0e9;
      margin-top: 10px;
      p {
        margin: 0;
        padding: 0;
        color: #8e9396;
      }
      .left {
        height: 100%;
        width: 380px;
        img {
          vertical-align: bottom;
          height: 100%;
        }
        .people {
          height: 100%;
          margin-left: 14px;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          .el-button {
            width: 80px;
            height: 28px;
            padding: 0 4px;
          }
          p:first-child {
            span:first-child {
              color: #000;
              font-size: 20px;
              font-weight: 600;
              margin-right: 20px;
            }
          }
        }
      }
      .center {
        width: 110px;
        p:first-child {
          margin-bottom: 8px;
          color: #e92f00;
          .score {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
      .right {
        p:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  .online {
    min-height: 500px;
  }
}
</style>
