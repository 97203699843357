<template>
  <!-- 土地价值评估 -->
  <div class="evaluate">
    <div class="first-step" v-if="currentStep === 0">
      <div class="s-title">
        <span class="title">土地介绍</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="区域">
              <el-select v-model="form.xzqbh">
                <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产权类型">
              <el-select v-model="form.cqlxbh">
                <el-option v-for="item in typeOptions" :key="item.lx" :label="item.lx" :value="item.bh"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="权证有效期限">
              <el-col :span="6">
                <el-date-picker
                  v-model="form.start"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="6">
                <el-date-picker
                  v-model="form.end"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="面积">
              <el-col :span="6">
                <el-input v-model="form.zxmj"></el-input>
              </el-col>
              <el-col :span="8">
                <el-select style="width: 100px;" v-model="form.unit">
                  <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="流转年限">
              <el-col :span="6">
                <el-input v-model="form.lznx"></el-input>
              </el-col>
              <el-col class="line" :span="1">年</el-col>
            </el-form-item>
            <el-form-item label="权属类型">
            <el-radio-group style="width: 100%;" v-model="form.lzlx">
              <el-radio-button
                v-for="item in flowTypeOptions"
                :key="item.name"
                :label="item.label"
                :name="item.name"
                :value="item.name"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="next" type="primary" @click="next">下一步</el-button>
      </div>
    </div>

    <div class="second-step" v-if="currentStep === 1">
      <div class="s-title">
        <span class="title">地块情况</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="土壤质地">
              <el-radio-group
                v-model="form.quality">
                <el-radio v-for="item in qualityOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="土壤厚度">
              <el-radio-group
                v-model="form.heavy">
                <el-radio v-for="item in heavyOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="土地肥力">
              <el-radio-group
                v-model="form.nutrition">
                <el-radio v-for="item in nutritionOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="地块形状">
              <el-radio-group
                v-model="form.shape">
                <el-radio v-for="item in shapeOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="平整程度">
              <el-radio-group
                v-model="form.smoothLevel">
                <el-radio v-for="item in smoothLevelOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="灌溉条件">
              <el-radio-group
                v-model="form.waterCondition">
                <el-radio v-for="item in waterConditionOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排水条件">
              <el-radio-group
                v-model="form.drainageCondition">
                <el-radio v-for="item in drainageConditionOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="next" type="" @click="prev">上一步</el-button>
        <el-button class="next" type="primary" @click="next">下一步</el-button>
      </div>
    </div>

    <div class="third-step" v-if="currentStep === 2">
      <div class="s-title">
        <span class="title">配套设施</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="供水">
              <el-radio-group
                v-model="form.waterSupply">
                <el-radio v-for="item in supplyOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="供电">
              <el-radio-group
                v-model="form.electricSupply">
                <el-radio v-for="item in supplyOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="网络">
              <el-radio-group
                v-model="form.net">
                <el-radio v-for="item in supplyOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="进入道路">
              <el-radio-group
                v-model="form.road">
                <el-radio v-for="item in roadOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="机械化程度">
              <el-radio-group
                v-model="form.mechanicalLevel">
                <el-radio v-for="item in mechanicalLevelOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="附属设施">
              <el-checkbox-group
                v-model="form.tools">
                <el-checkbox-button v-for="item in toolsOptions" :label="item.value" :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="服务配套">
              <el-checkbox-group
                v-model="form.service">
                <el-checkbox-button v-for="item in serviceOptions" :label="item.value" :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="next" type="" @click="prev">上一步</el-button>
        <el-button class="next" type="primary" @click="next">下一步</el-button>
      </div>
    </div>

    <div class="fourth-step" v-if="currentStep === 3">
      <div class="s-title">
        <span class="title">交通</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="机场">
              <el-radio-group
                v-model="form.airport">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="高铁">
              <el-radio-group
                v-model="form.subway">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="高速">
              <el-radio-group
                v-model="form.highway">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="铁路">
              <el-radio-group
                v-model="form.train">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="国道">
              <el-radio-group
                v-model="form.road">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="港口">
              <el-radio-group
                v-model="form.port">
                <el-radio v-for="item in distanceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="next" type="" @click="prev">上一步</el-button>
        <el-button class="next" type="primary" @click="next">下一步</el-button>
      </div>
    </div>

    <div class="fifth-step" v-if="currentStep === 4">
      <div class="s-title">
        <span class="title">经营现状</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="160px">
            <el-form-item label="地块利用">
              <el-checkbox-group
                v-model="form.use">
                <el-checkbox-button
                  style="margin-bottom: 12px;"
                  v-for="item in useOptions"
                  :label="item.value"
                  :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="种植作物">
              <el-checkbox-group
                v-model="form.crop">
                <el-checkbox-button v-for="item in cropOptions" :label="item.value" :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="当地主要收入来源">
              <el-radio-group
                v-model="form.inputSource">
                <el-radio v-for="item in inputSourceOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="劳动力来源">
              <el-radio-group
                v-model="form.labor">
                <el-radio v-for="item in laborOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="当地农民人均年纯收入">
              <el-radio-group
                v-model="form.income">
                <el-radio v-for="item in incomeOptions" :label="item.value" :key="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="next" type="" @click="prev">上一步</el-button>
        <el-button class="next" type="primary" @click="next">下一步</el-button>
      </div>
    </div>

    <div class="sixth-step" v-if="currentStep === 5">
      <div class="s-title">
        <span class="title">惠农政策</span>
      </div>
      <div class="content">
        <div class="step-form">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="补贴">
              <el-checkbox-group
                v-model="form.subsidy">
                <el-checkbox-button
                  style="margin-bottom: 12px;"
                  v-for="item in subsidyOptions"
                  :label="item.value"
                  :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="政策">
              <el-checkbox-group
                v-model="form.policy">
                <el-checkbox-button v-for="item in policyOptions" :label="item.value" :key="item.name">{{item.name}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
        <p class="tip">请提供真实数据以供评估，评估结果以供参考</p>
        <el-button class="next" type="" @click="prev">上一步</el-button>
        <el-button class="next submit" type="primary" @click="submit">立即评估</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getPropertyType } from '../../../api/exchange'
export default {
  data() {
    return {
      currentStep: 0,
      form: {tools: [], service: [], use: [], crop: [], subsidy: [], policy: []},
      areaOptions: [
        {}
      ],
      typeOptions: [
        {}
      ],
      flowTypeOptions: [
        {label: '个人', name: '0'}, {label: '企业', name: '1'}, {label: '国有', name: '2'}, {label: '集体', name: '3'},
      ],
      unitOptions: [
        {label: '亩', value: '亩'}, {label: '平方米', value: '平方米'},
      ],
      qualityOptions: [
        {name: '壤土', value: '0'}, {name: '粘土', value: '1'}, {name: '沙土', value: '2'}, {name: '砂砾土', value: '3'},
      ],
      heavyOptions: [
        {name: '>150cm', value: '0'}, {name: '100-150cm', value: '1'}, {name: '60-100cm', value: '2'},
        {name: '30-60cm', value: '3'}, {name: '<30cm', value: '4'},
      ],
      nutritionOptions: [
        {name: '极高', value: '0'}, {name: '较高', value: '1'}, {name: '一般', value: '2'},
        {name: '较低', value: '3'}, {name: '极低', value: '4'},
      ],
      shapeOptions: [
        {name: '规则', value: '0'}, {name: '一般', value: '1'}, {name: '不规则', value: '2'},
        {name: '壤土', value: '3'},
      ],
      smoothLevelOptions: [
        {name: '非常平整', value: '0'}, {name: '平整', value: '1'}, {name: '一般', value: '2'},
        {name: '不平整', value: '3'}, {name: '非常不平整', value: '4'}
      ],
      waterConditionOptions: [
        {name: '充分满足', value: '0'}, {name: '基本满足', value: '1'}, {name: '一般满足', value: '2'},
        {name: '无灌溉条件', value: '3'},
      ],
      drainageConditionOptions: [
        {name: '充分满足', value: '0'}, {name: '基本满足', value: '1'}, {name: '一般满足', value: '2'},
        {name: '无排水体系', value: '3'},
      ],
      supplyOptions: [
        {name: '已接通', value: '0'}, {name: '暂未接通，但可接通', value: '1'}, {name: '难接通', value: '2'},
      ],
      roadOptions: [
        {name: '水路', value: '0'}, {name: '土路', value: '1'}, {name: '水泥路', value: '2'},
        {name: '机耕路', value: '3'},
      ],
      mechanicalLevelOptions: [
        {name: '完全机械化', value: '0'}, {name: '基本机械化', value: '1'}, {name: '简单机械化', value: '2'},
        {name: '难以机械化', value: '3'},
      ],
      toolsOptions: [
        {name: '温室大棚', value: '0'}, {name: '畜禽社', value: '1'}, {name: '农机房', value: '2'},
        {name: '晾晒场', value: '3'}, {name: '生活用房', value: '4'}, {name: '水井', value: '5'},
        {name: '蓄水池', value: '6'},
      ],
      serviceOptions: [
        {name: '农技站', value: '0'}, {name: '农副产品批发市场', value: '1'}, {name: '冷藏保鲜', value: '2'},
        {name: '仓储', value: '3'}, {name: '物流', value: '4'},
      ],
      distanceOptions: [
        {name: '0-10km', value: '0'}, {name: '10-50km', value: '1'}, {name: '50-100-km', value: '2'},
        {name: '100km以上', value: '3'},
      ],
      useOptions: [
        {name: '水稻', value: '0'}, {name: '小麦', value: '1'}, {name: '玉米', value: '2'},
        {name: '薯类', value: '3'}, {name: '油料', value: '4'}, {name: '粮料', value: '5'},
        {name: '烟草', value: '6'}, {name: '棉花', value: '7'}, {name: '果树', value: '8'},
        {name: '林木', value: '9'}, {name: '牛', value: '10'}, {name: '马', value: '11'},
        {name: '羊', value: '12'}, {name: '猪', value: '13'}, {name: '禽类', value: '14'},
        {name: '其他', value: '15'},
      ],
      cropOptions: [
        {name: '种植作物', value: '0'}, {name: '种植树木', value: '1'}, {name: '养殖', value: '2'},
        {name: '休闲农业', value: '3'},
      ],
      inputSourceOptions: [
        {name: '在家种养', value: '0'}, {name: '外出务工', value: '1'}
      ],
      laborOptions: [
        {name: '充足', value: '0'}, {name: '一般', value: '1'}, {name: '匮乏', value: '2'},
      ],
      incomeOptions: [
        {name: '1千元以下', value: '0'}, {name: '1千-3千元', value: '1'}, {name: '3千-5千元', value: '2'},
        {name: '5千-1万元', value: '3'}, {name: '1万元以上', value: '4'},
      ],
      subsidyOptions: [
        {name: '种粮直补', value: '0'}, {name: '良种补贴', value: '1'}, {name: '农资补贴', value: '2'},
        {name: '农机购置补贴', value: '3'}, {name: '农机报废补贴', value: '4'},
        {name: '土地改补', value: '4'}, {name: '动物防疫补贴', value: '5'},
      ],
      policyOptions: [
        {name: '贷款贴息', value: '0'}, {name: '利息优惠', value: '1'}, {name: '税收优惠', value: '2'},
        {name: '最低收购价', value: '3'}, {name: '畜禽扶植政策', value: '4'},
      ],
    }
  },
  created() {
    this.initPropertyType();
  },
  methods: {
    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        this.typeOptions = data;
      }
    },
    prev() {
      // 上一步
      this.currentStep --;
    },
    next() {
      // 下一步
      this.currentStep ++;
    },
    submit() {
      // 立即评估
      this.form = {tools: [], service: [], use: [], crop: [], subsidy: [], policy: []};
      // this.currentStep = 0;
    }
  }
}
</script>

<style lang="less" scoped>
.evaluate {
  width: 100%;
  background: #fff;
  padding-bottom: 20px;
  .content {
    margin-top: 38px;
    width: 100%;
    text-align: center;
    .next {
      width: 224px;
      margin-top: 28px;
    }
    .tip {
      margin: 0;
      color: #ccc;
      margin-top: 58px;
    }
    .submit {
      margin: 10px;
    }
  }
  .step-form {
    text-align: left;
    .el-radio-group {
      text-align: left;
    }
    .el-radio-button, .el-radio-button__inner {
      margin-right: 16px;
      width: 89px;
    }
    .el-checkbox-button, .el-checkbox-button__inner {
      margin-right: 16px;
      // width: 89px;
    }
    .el-select {
      width: 250px;
    }
    .el-input {
      width: 190px;
    }
    .line {
      text-align: center;
    }
  }
}
</style>

<style lang="less">
.step-form {
  .el-radio-button__inner {
    width: 89px;
    border: 1px solid #cfcfcf;
  }
  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color:#00a0e9;
  }
  .el-checkbox-button__inner {
    // width: 89px;
    border: 1px solid #cfcfcf;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color:#00a0e9;
  }
}
</style>
